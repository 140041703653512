import React from 'react';

import { graphql, HeadProps, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import { Layout, MainMasthead, Masthead, Seo } from '../components';

const title = '404';
const description = '페이지를 찾을 수 없어요.';

const NotFoundPage = ({
  data,
}: PageProps<{
  background: ImageDataLike;
}>) => {
  const { background } = data;

  return (
    <Layout>
      <Masthead background={background}>
        <MainMasthead title={title} description={description} />
      </Masthead>
    </Layout>
  );
};

export default NotFoundPage;

export const NotFoundQuery = graphql`
  query {
    background: file(relativePath: { eq: "Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const Head = ({ location }: HeadProps) => {
  return (
    <Seo title={title} description={description} path={location.pathname} />
  );
};
